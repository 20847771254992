import React from "react";
import "./InfoScreen.css";
import moonpic from "../Images/moon_pic.webp";
import mooneclipse from "../Images/ispace-eclipse-from-moon.webp";

function InfoScreen(props) {
  return (
    <div className="InfoScreen_box">
      <div className="InfoScreen_nav">
        <button
          className="InfoScreen_close"
          onClick={props.onCloseInfoScreen}
        ></button>
      </div>
      <div className="InfoScreen_content">
        <div className="InfoScreen_header">
          <span>SUCCESS {props.success}</span>
          <h3>{props.eng === true ? "ARTICLES" : "ニュース記事"}</h3>
        </div>
        {props.success === 1 ? (
          <div>
            <div className="article_box">
              <div>
                <span>newscientist.com: </span>
                <a
                  href="https://www.newscientist.com/article/2348517-japanese-firm-ispace-is-racing-to-put-first-private-lander-on-the-moon/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Japanese firm ispace is racing to put first private lander on
                  the moon
                </a>
              </div>
              <div>
                <span>spacenews.com: </span>
                <a
                  href="https://spacenews.com/japanese-lunar-lander-with-nasa-rideshare-to-launch-this-month/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Japanese lunar lander, with NASA rideshare, to launch this
                  month
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {props.success === 2 ? (
          <div>
            <div className="article_box">
              <div>
                <span>spacenews.com: </span>
                <a
                  href="https://spacenews.com/falcon-9-launches-ispace-lander-and-nasa-cubesat-to-the-moon/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Falcon 9 launches ispace lander and NASA cubesat to the moon
                </a>
              </div>
              <div>
                <span>reuters.com: </span>
                <a
                  href="https://www.reuters.com/lifestyle/science/japans-ispace-readies-delayed-launch-first-commercial-moon-lander-2022-12-10/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Japan's ispace launches commercial moon lander, in potential
                  world first
                </a>
              </div>
              <div>
                <span>spaceflightnow.com: </span>
                <a
                  href="https://spaceflightnow.com/2022/12/11/falcon-9-ispace-mission-1-live-coverage/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Japanese moon lander, NASA hitchhiker payload launched by
                  SpaceX
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {props.success === 3 ? (
          <div>
            <div className="article_box">
              <div>
                <span>spacewatch.global: </span>
                <a
                  href="https://spacewatch.global/2022/12/ispace-mission-1-lander-establishes-stable-communications-with-earth/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ispace Mission 1 lander establishes stable communications with
                  Earth
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {props.success === 4 ? (
          <div>
            <div className="article_box">
              <div>
                <span>spaceref.com: </span>
                <a
                  href="https://spaceref.com/space-commerce/ispace-successfully-carries-out-second-hakuto-r-orbital-control-maneuver-2-jan-2023/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ispace Successfully Carries Out Second HAKUTO-R Orbital
                  Control Maneuver 2 Jan 2023
                </a>
              </div>
              <div>
                <span>thediplomat.com: </span>
                <a
                  href="https://thediplomat.com/2023/01/japan-is-changing-the-game-for-space-powers/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Japan Is Changing the Game for Space Powers
                </a>
              </div>
              <div>
                <span>space.com:</span>
                <a
                  href="https://www.space.com/ispace-moon-lander-second-deep-space-maneuver"
                  target="_blank"
                  rel="noreferrer"
                >
                  Private Japanese lunar lander performs 2nd major maneuver on
                  its way to the moon
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {props.success === 5 ? (
          <div>
            <div className="article_box">
              <div>
                <span>gizmodo.com: </span>
                <a
                  href="https://gizmodo.com/hakuto-r-lander-travels-farther-private-spacecraft-1850172703"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lunar Lander Travels Deeper Into Space Than Any Other
                  Commercial Spacecraft
                </a>
              </div>
              <div>
                <span>space.com: </span>
                <a
                  href="https://www.space.com/japanese-moon-lander-hakuto-r-first-photos"
                  target="_blank"
                  rel="noreferrer"
                >
                  Private Japanese moon lander snaps 1st photos in deep space
                </a>
              </div>
              <div>
                <span>spacewatch.global: </span>
                <a
                  href="https://spacewatch.global/2023/01/ispace-completes-success-5-of-mission-1-milestones/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ispace Completes Success 5 of Mission 1 Milestones
                </a>
              </div>
              <a href={moonpic} target="_blank" rel="noreferrer">
                <img
                  src={moonpic}
                  alt="hakuto_first_photo"
                  className="infoscreen_photo_1"
                />
              </a>
              <figcaption>First picture by Hakuto-R</figcaption>
            </div>
          </div>
        ) : null}
        {props.success === 6 ? (
          <div>
            <div className="article_box">
              <div>
                <span>space.com: </span>
                <a
                  href="https://www.space.com/ispace-hakuto-r-moon-lander-distance-record"
                  target="_blank"
                  rel="noreferrer"
                >
                  Private Japanese lander sets distance record on its way to the
                  moon
                </a>
              </div>
              <div>
                <span>marketplace.org: </span>
                <a
                  href="https://www.marketplace.org/2023/02/15/a-new-moon-race-is-underway/"
                  target="_blank"
                  rel="noreferrer"
                >
                  A new moon race is underway
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {props.success === 7 ? (
          <div>
            <div className="article_box">
              <div>
                <span>aviationweek.com: </span>
                <a
                  href="https://aviationweek.com/aerospace/commercial-space/ispace-hakuto-r-mission-1-lunar-lander-enters-moons-orbit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ispace Hakuto-R Mission 1 Lunar Lander Enters Moon’s Orbit
                </a>
              </div>
              <div>
                <span>gizmodo.com: </span>
                <a
                  href="https://gizmodo.com/private-japanese-hakuto-r-lander-enters-moon-orbit-1850247809"
                  target="_blank"
                  rel="noreferrer"
                >
                  Private Japanese Lander Enters Moon's Orbit Ahead of Historic
                  Landing Attempt
                </a>
              </div>
              <div>
                <span>spacenews.com: </span>
                <a
                  href="https://spacenews.com/japanese-lander-enters-lunar-orbit/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Japanese lander enters lunar orbit
                </a>
              </div>
              <div>
                <span>devdiscourse.com: </span>
                <a
                  href="https://www.devdiscourse.com/article/science-environment/2405997-japans-hakuto-r-m1-lunar-lander-remains-in-stable-orbit-around-moon"
                  target="_blank"
                  rel="noreferrer"
                >
                  Japan's Hakuto-R M1 lunar lander remains in stable orbit
                  around Moon
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {props.success === 8 ? (
          <div>
            <div className="article_box">
              <div>
                <span>universetoday.com: </span>
                <a
                  href="https://www.universetoday.com/161051/hakuto-r-spacecraft-just-captured-its-own-stunning-version-of-earthrise/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hakuto-R Spacecraft Just Captured its Own Stunning Version of
                  ‘Earthrise’
                </a>
              </div>
              <div>
                <span>space.com: </span>
                <a
                  href="https://www.space.com/japan-hakuto-r-moon-lander-photo-orbit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Private Japanese moon lander sends home stunning image from
                  lunar orbit
                </a>
              </div>
              <div>
                <span>thenationalnews.com: </span>
                <a
                  href="https://www.thenationalnews.com/uae/2023/03/27/lander-carrying-uae-rover-sends-back-first-image-of-moon/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lander carrying UAE rover sends back first image of Moon
                </a>
              </div>
              <div>
                <span>nature.com: </span>
                <a
                  href="https://www.nature.com/articles/d41586-023-01423-0"
                  target="_blank"
                  rel="noreferrer"
                >
                  Private Moon mission prepares for historic landing attempt
                </a>
              </div>
              <a href={mooneclipse} target="_blank" rel="noreferrer">
                <img
                  src={mooneclipse}
                  alt="hakuto_first_photo"
                  className="infoscreen_photo_1"
                />
              </a>
              <figcaption>
                The photo from the outher world that went around the world{" "}
              </figcaption>
            </div>
          </div>
        ) : null}
        {props.success === 9 ? (
          <div>
            <div className="article_box">
              <div>
                <span>space.com: </span>
                <a
                  href="https://www.space.com/ispace-hakuto-r-moon-landing-failure"
                  target="_blank"
                  rel="noreferrer"
                >
                  Private Japanese spacecraft apparently fails on historic moon
                  landing try
                </a>
              </div>
              <div>
                <span>independent.co.uk: </span>
                <a
                  href="https://www.independent.co.uk/space/moon-landing-live-stream-ispace-b2326328.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Moon landing attempt: World’s first commercial lunar lander
                  presumed lost
                </a>
              </div>
              <div>
                <span>yahoo.com: </span>
                <a
                  href="https://www.yahoo.com/entertainment/doomed-lunar-lander-captured-view-212422542.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Doomed Lunar Lander Captured a View of Earth That’s to Die For
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {props.success === 10 ? (
          <div>
            <div className="article_box">
              <div>
                <span>japan-forward.com: </span>
                <a
                  href="https://japan-forward.com/ispace-fails-moon-challenge-but-gains-insight-for-future-missions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ispace Fails Moon Challenge but Gains Insight for Future
                  Missions
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default InfoScreen;
