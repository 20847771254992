import React, {useState} from 'react';
import {signInWithEmailAndPassword,signOut} from 'firebase/auth';

import "./Login.css";

import { auth } from '../FirebaseConfig';
import { NavLink, useNavigate } from 'react-router-dom'
 
const Login = (props) => {
    const eng = props.eng

    const navigate = useNavigate();
    const [error, setError] = useState(false)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            //const user = userCredential.user;
            navigate("/home")
            //console.log(user);
            setError(false)
            console.log("Succesfully logged")
        })
        .catch((error) => {
            setError(true)
            //const errorCode = error.code;
             // const errorMessage = error.message;
            //console.log(errorCode, errorMessage)
        });
       
    }
 

    return(
            <main className="loginScreen">        
                        <div className="lang-box lang-box-login">
                            <button
                                onClick={() => props.onEng(true)}
                                className={eng === true ? "selected" : ""}
                            >
                                English
                            </button>
                            <button
                                onClick={() => props.onEng(false)}
                                className={eng !== true ? "selected" : ""}
                            >
                                日本語
                            </button>
                        </div>
                        <h3>{eng ? 
                             "Welcome visitor" :
                             "ようこそ"
                        }</h3> 
                        <p> {eng ?
                         "To proceed further, please login with the address and password provided you by the site administrator." :
                          "続行するには、サイト管理者から提供されたアドレスとパスワードでログインしてください。"
                        }</p>                                                          
                        <form  className="loginScreenForm">                                              
                            <div>
                                <label htmlFor="email-address">
                                    {eng ? "Email address" : "メールアドレス"}
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"                                    
                                    required                                                                                
                                    placeholder="Email address"
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="password">
                                {eng ? "Password" : "パスワード"}
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"                                    
                                    required                                                                                
                                    placeholder="Password"
                                    onChange={(e)=>setPassword(e.target.value)}
                                />
                            </div>
                                                
                            <div>
                                <button onClick={onLogin}>      
                                    L•GIN                                                                  
                                </button>
                            </div> 
                            {error && <p className="errorMessage">Wrong email address or password!</p>}                              
                        </form>
            </main>
    )
}
 
export default Login


/*
    const handleLogout = () => {               
      signOut(auth).then(() => {
      // Sign-out successful.
          navigate("/");
          console.log("Signed out successfully")
      }).catch((error) => {
      // An error happened.
      });
  }

    <button onClick={handleLogout}>
        Logout
    </button>
*/