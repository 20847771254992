//MATERIALS
function MetalLightMaterial () {
    return <meshPhysicalMaterial color='#ababab' roughness = {0.4} metalness = {0.6}
    reflectivity = {0.65} side="DoubleSide" emissive = "#242424"/>
}

function MetalDarkMaterial () {
    return <meshPhysicalMaterial color='#6e6e6e' roughness = {0.4} metalness = {0.6}
    reflectivity = {1} side="DoubleSide" emissive = "#000000" />
}

//GEOMETRIES
function LegLong () {return <cylinderGeometry args={[ 0.03,0.05,2.5,32]}/>}
function LegShort () {return <cylinderGeometry args={[0.02,0.02,1,32]}/>}
function LegShord () {return <cylinderGeometry args={[0.05,0.05,0.5,32]}/>}
function LegShoe () {return <cylinderGeometry args={[0.15,0.15,0.05,32]}/>}
function LegConnector () {return <cylinderGeometry args={[0.07,0.13,0.5,32]}/>}

export default function Leg (props) {
    return (

        <group rotation={props.legrot}>
        {/*leg_1_long*/}
        <mesh position={[0,-0.8,1.5]} rotation-x={Math.PI * 0.75}>
           <LegLong/>
           <MetalLightMaterial/>
        </mesh>

        {/*leg_1_short_l*/}
        <mesh position={[-0.4, -0.66, 1.05]} rotation={[0, -Math.PI * 0.12, Math.PI * 0.6]}>
           <LegShort/>
           <MetalLightMaterial/>
        </mesh>

        {/*leg_1_shord_l*/}
        <mesh position={[-0.4, -0.66, 1.05]} rotation={[0, -Math.PI * 0.12, Math.PI * 0.6]}>
           <LegShord/>
           <MetalDarkMaterial/>
        </mesh>

       {/*leg_1_short_r*/}
        <mesh position={[0.4, -0.66, 1.05]} rotation={[0, Math.PI * 0.12, -Math.PI * 0.6]}>
           <LegShort/>
           <MetalLightMaterial/>
        </mesh>

       {/*leg_1_short_d*/}
        <mesh position={[0.4, -0.66, 1.05]} rotation={[0, Math.PI * 0.12, -Math.PI * 0.6]}>
           <LegShord/>
           <MetalDarkMaterial/>
        </mesh>

       {/*leg_1_shoe */}
        <mesh position={[0,-1.7,2.35]}>
           <LegShoe/>
           <MetalDarkMaterial/>
        </mesh>
       {/*leg_1_joint */}
        <group position={[0,-0.3,1]} >
           {/*leg_1_joint1 */}
           <mesh>
               <boxGeometry args={[0.7, 0.05, 0.08]}/>
               <MetalDarkMaterial/>
           </mesh>  
           {/*leg_1_joint2 */}                  
           <mesh rotation-y={Math.PI*0.5}>
               <boxGeometry args={[0.05, 0.7, 0.05]}/>
               <MetalDarkMaterial/>
           </mesh>   
        </group>
        <mesh position={[0.7,-0.75,0.7]} rotation={[0,-Math.PI*0.25,-Math.PI*0.6]}>
           <LegConnector/>
           <MetalDarkMaterial/>
        </mesh>

</group> 

    )
}