import "../App.css";
import "../Range.css";
import { useState, useEffect, useRef } from "react";

import {Link} from 'react-router-dom'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../FirebaseConfig';
import { NavLink, useNavigate } from 'react-router-dom'
//img
import hakuro1 from "../Images/img_mission1.png";
import ispace from "../Images/logo_ispace.svg";
import ispace_w from "../Images/logo_ispace_white.svg";
import hakuto from "../Images/icon_hakuto-r.svg";
import earth from "../Images/earth.png";
import moon from "../Images/moon.png";
import thrust from "../Images/thrust.png";
import booster from "../Images/booster.png";
import moonpic from "../Images/moon_pic.webp";
import mooneclipse from "../Images/ispace-eclipse-from-moon.webp";
import canadensys from "../Images/canadensys.jpg";

//Component
import InfoScreen from "./InfoScreen";
import HelperStory from "./HelperStory";


function Story(props) {
  const eng = props.eng
  const help = props.help
  const navigate = useNavigate();

  const [interesting, setInteresting] = useState(false);
  const interestingHandler = (value) => {
    setInteresting(value);
  };

  const [interesting2, setInteresting2] = useState(false);
  const interesting2Handler = (value) => {
    setInteresting2(value);
  };

  const [interesting3, setInteresting3] = useState(false);
  const interesting3Handler = (value) => {
    setInteresting3(value);
  };

  const [interesting4, setInteresting4] = useState(false);
  const interesting4Handler = (value) => {
    setInteresting4(value);
  };

  const [cookie, setCookie] = useState(false);
  const cookieHandler = (value) => {
    setCookie(value);
  };
/*
  const [help, setHelp] = useState(true);
  const helpHandler = () => {
    setHelp(!help);
  };
*/
  const [hint, setHint] = useState(false);
  const hintHandler = (value) => {
    setHint(value);
  };

  const [step, setStep] = useState(0);

  const successStepHandler = (val) => {
    setStep(val);
    setSuccess(val / 10);
  };

  const [modal, setModal] = useState(false);
  const modalHadler = (value) => {
    value === undefined || value === null ? setModal(!modal) : setModal(value);
  };

  const [success, setSuccess] = useState(1);
  const successHandler = (n) => {
    setSuccess(n);
  };

  const handleChange = (e) => {
    setStep(Number(e.target.value));
  };

  const currentStep = useRef();


  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          // ...
          //console.log("uid", uid)
        } else {
          // User is signed out
          // ...
          navigate("/");
          console.log("user is logged out")
        }
      });
     
}, [])

  useEffect(() => {
    return () => clearInterval(currentStep.current);
  }, []);

  useEffect(() => {
    if (Number(step) > 100) {
      stopStep();
    }
    if (Number(step) === 0) {
      setModal(false);
    }
  }, [step]);

  const startStep = () => {
    clearInterval(currentStep.current);
    currentStep.current = setInterval(() => {
      setStep((prevStep) => prevStep + 0.01);
    }, 10);
  };
  const stopStep = () => {
    clearInterval(currentStep.current);
  };
  const resetStep = () => {
    clearInterval(currentStep.current);
    setStep(0);
  };

  const ispace_logo_style = {
    width: `${15 - step * 2.5}vw`,
    minWidth: "5vw",
    opacity: `${2 - step}`,
  };

  const hakuto_logo_style = {
    opacity: `${2 - step}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const hakuto_title = {
    fontSize: `${3.5 - step * 0.3}vw`,
    display: `${step > 2 ? "none" : "inline-block"}`,
  };


  const earth_style = {
    width: "50vw",
    position: "absolute",
    zIndex: "10",
    opacity: `${step <= 70 ? -2 + step : 1 - (step - 60) * 0.1}`,
    left: `${
      step <= 10
        ? -15 + step * 2
        : step <= 20
        ? 25 - step * 2.5
        : step <= 30
        ? -25
        : step <= 40
        ? (step - 30) * 3 - 25
        : step <= 50
        ? 5 - (step - 40) * 1.7
        : step <= 60
        ? -12 - (step - 50) * 1.8 //-30
        : step <= 70
        ? -30 - (step - 60) * 12
        : -50
    }%`,
    bottom: `${
      step <= 10
        ? 20 / step + 20
        : step <= 20
        ? 48 - step * 4
        : step <= 30
        ? -32
        : step <= 40
        ? (step - 30) * 5.2 - 32
        : step <= 50
        ? 20
        : step <= 60
        ? 20 + (step - 50) * 2 //40
        : step <= 70
        ? 40 - (step - 60) * 15
        : 10
    }%`,
    transform: `scale(${
      step <= 10
        ? step * 0.1
        : step <= 20
        ? 1 + step * 0.005
        : step <= 30
        ? 1
        : step <= 40
        ? 1 - (step - 30) * 0.03
        : step <= 50
        ? 0.7 - (step - 40) * 0.01
        : step <= 60
        ? 0.6 - (step - 50) * 0.01 //0.5
        : step <= 70
        ? 0.5 + (step - 60) * 0.4
        : 1
    })`,
    transition: `0.2s`,
  };

  const moon_style = {
    width: "5vw",
    display: `${step <= 40 ? "none" : "inline-block"}`,
    position: "absolute",
    right: `${
      step <= 40
        ? -5
        : step <= 50
        ? -5 + (step - 40) * 2
        : step <= 60
        ? 15 + (step - 50) * 0.5 //20
        : step <= 70
        ? 20 + (step - 60) * 2.8
        : step <= 80
        ? 48
        : 48
    }%`,
    bottom: `${
      step <= 50
        ? 50
        : step <= 60
        ? 50 + (step - 50) * 1.5
        : step <= 70
        ? 65 - (step - 60) * 5
        : step <= 80
        ? 15 + (step - 70) * 3 //45
        : step <= 90
        ? 45 - (step - 80) * 6
        : -15
    }%`,
    transform: `scale(${
      step <= 40
        ? 0.6
        : step <= 50
        ? 0.6 + (step - 40) * 0.04
        : step <= 60
        ? 1 + (step - 50) * 0.07
        : step <= 70
        ? 1 + (step - 60) * 1.1
        : step <= 80
        ? 12 - (step - 70) * 0.4 //8
        : step <= 90
        ? 8 + (step - 80) * 1.7
        : 25
    })`,
    zIndex: `${step <= 70 ? 1 : step <= 78 ? 3 : 1}`,
    transition: `0.2s`,
  };

  const main_screen_style = {
    boxShadow: `inset 0 0 0 2000px rgba(255, 255, 255, ${1 - step * 0.4})`,
  };

  const ispace_nav_style = {
    opacity: `${-5 + step}`,
    display: `${step > 0 ? "flex" : "none"}`,
  };

  const success1_style = {
    left: `${step <= 10 ? -40 + step * 9 : 50}%`,
    transform: `scale(${step <= 10 ? step * 0.1 : 1})`,
    opacity: `${step > 10 ? 1 - (step - 10) * 0.3 : -7 + step}`,
    transition: "0.2s",
  };

  const success2_style = {
    left: `${step <= 20 ? 50 - step * 1.5 : 20}%`,
    transform: `scale(${step <= 20 ? step * 0.1 - 1 : 1})`,
    opacity: `${step > 20 ? 1 - (step - 20) * 0.2 : -14 + step}`,
    transition: "0.2s",
    zIndex: `${step >= 20 ? 100 : 0}`,
  };

  const success3_style = {
    width: "60%",
    opacity: `${step <= 30 ? step - 28 : 35 - step}`,
    bottom: "28%",
  };

  const success4_style = {
    width: "35%",
    opacity: `${step <= 40 ? step - 38 : 45 - step}`,
    bottom: "20%",
    right: "15%",
  };

  const success5_style = {
    width: "45%",
    opacity: `${step <= 50 ? step - 48 : 55 - step}`,
    bottom: "20%",
  };

  const success6_style = {
    width: "35%",
    opacity: `${step <= 60 ? step - 58 : 65 - step}`,
    bottom: "20%",
    left: "5%",
  };

  const success7_style = {
    width: "50%",
    opacity: `${step <= 70 ? step - 68 : 75 - step}`,
    top: "8%",
  };

  const success8_style = {
    width: "25%",
    opacity: `${step <= 80 ? (step - 75) * 0.2 : 85 - step}`,
    transform: `scale(${step <= 80 ? (step - 75) * 0.2 : 1})`,
    top: "35%",
    left: `${step <= 80 ? 45 - (step - 75) * 8 : 5}%`, //"5%",
    zIndex: `${step >= 80 ? 100 : 0}`,
  };

  const success9_style = {
    width: "50%",
    opacity: `${step <= 90 ? step - 88 : 95 - step}`,
    top: "8%",
    zIndex: "10",
    filter: "saturate(20%)",
  };

  const success10_style = {
    width: "20%",
    opacity: `${step <= 100 ? step - 99.4 : 1}`,
    top: "12%",
    left: "3%",
    zIndex: "0",
    filter: "saturate(20%)",
  };

  const rocket_light_style = {
    transform: `scale(${step <= 14 ? step * 0.2 - 1.5 : step * 0.35 - 1.5})`,
    left: `${step * 3.3 - 0.7}%`,
    opacity: `${step <= 10 ? 0 : step <= 14 ? 1 : 15 - step}`,
    zIndex: `3`,
    transition: "0.5s",
  };

  const booster_style = {
    transform: `scale(${step * 0.2 - 1.7}) rotate(${
      step <= 15 ? 0 : -14 + step * 3
    }deg)`,
    left: `${step <= 15 ? step * 3.3 : 60.2 - step}%`,
    opacity: `${step <= 10 ? 0 : step <= 14 ? -11 + step : 20 - step}`,
    zIndex: `2`,
    transition: "0.5s",
  };

  const hakuto1_style = {
    transform: `scale(${
      step <= 20
        ? step * step * step * step * step * step * 0.0000000625
        : step <= 31
        ? 4
        : step <= 35
        ? 35 - step * 0.98
        : step <= 36.5
        ? 0.3
        : step <= 40
        ? 0.3 + (step - 36.5) * 0.1 + 0.05
        : step <= 50
        ? 0.7 - (step - 40) * 0.01
        : step <= 60
        ? 0.6 + (step - 50) * 0.19
        : step <= 70
        ? 2.5 - (step - 60) * 0.15 //1
        : step <= 75
        ? 1 - (step - 70) * 0.4 //0.05
        : step <= 80
        ? 0.05 + (step - 75) * 0.11 //0.6
        : step <= 90
        ? 0.6 - (step - 80) * 0.04
        : 0.2
    })`,
    opacity: `${step <= 14 ? 0 : step - 15}`,
    zIndex: `2`,
    filter: `brightness(${
      step >= 65 && step <= 74 ? 1 + (step - 65) * 0.3 : step >= 75 ? 1 : 1
    })`,
    transition: "0.2s",
  };

  const hakuto1_thrust_style = {
    transform: `scale(${
      step <= 20
        ? step * step * step * step * step * step * 0.0000000625
        : step <= 31
        ? 4
        : step <= 35
        ? 35 - step * 0.98
        : step <= 36.5
        ? 0.3
        : step <= 40
        ? 0.3 + (step - 36.5) * 0.1 + 0.05
        : step <= 60
        ? 0.6 + (step - 50) * 0.07
        : step <= 70
        ? 1.3 - (step - 60) * 0.05
        : step <= 75
        ? 1 - (step - 70) * 0.4
        : step <= 80
        ? 0.2 + (step - 75) * 0.02
        : step <= 90
        ? 0.6 - (step - 80) * 0.02
        : 0.4
    })`,
    opacity: `${
      step <= 18
        ? 0
        : step <= 50
        ? step - 19.2
        : step <= 60
        ? 1 - (step - 50) * 0.05
        : step > 85
        ? 0.7
        : 0.5
    }`,
    bottom: `${
      step <= 31
        ? -185
        : step <= 35
        ? -185 + (step - 31) * 30
        : step <= 50
        ? -20
        : step <= 60
        ? -20 - (step - 50) * 5
        : step <= 70
        ? -70 + (step - 60) * 3.5
        : step <= 73
        ? -35 + (step - 70) * 19
        : step <= 75
        ? 22 + (step - 73) * 2
        : step <= 80
        ? 18 - (step - 75) * 5
        : step <= 90
        ? 3 + (step - 80) * 0.3
        : 6
    }%`,
    left: `${step <= 31 ? 32 : step > 85 ? 39 : 38}%`,
    zIndex: `1`,
    transition: "0.2s",
  };

  const hakuto1_box_style = {
    left: `${
      step <= 22
        ? step * 3.3 - 2.5
        : step <= 32
        ? 92.1 - step
        : step <= 35
        ? 60.1 + (step - 32) * 6
        : step <= 36
        ? 0
        : step <= 36.5
        ? 25
        : step <= 40
        ? 25 + (step - 36.5) * 10
        : step <= 50
        ? 60 - (step - 40) * 1.3
        : step <= 60
        ? 47
        : step <= 70
        ? 47 - (step - 60) * 2.7
        : step <= 74
        ? 20 + (step - 70) * 4
        : step <= 76
        ? 36 + (step - 74) * 17
        : step <= 80
        ? 70 - (step - 76) * 4
        : step <= 90
        ? 54 - (step - 80) * 1.12
        : 42.8
    }%`,
    bottom: `${
      step <= 32
        ? 50
        : step <= 35
        ? 50 - (step - 32) * 20
        : step <= 36
        ? -10
        : step <= 36.5
        ? 50
        : step <= 40
        ? 50 + (step - 36.5) * 2.85
        : step <= 50
        ? 59.975 - (step - 40)
        : step <= 60
        ? 50 - (step - 50) * 2.5
        : step <= 70
        ? 25 + (step - 60) * 0.5
        : step <= 74
        ? 30 + (step - 70) * 3
        : step <= 76
        ? 42 - (step - 74) * 8
        : step <= 80
        ? 26 + (step - 76)
        : step <= 90
        ? 30 + (step - 80) * 1.7
        : 47
    }%`,
    opacity: `${
      step <= 14
        ? 0
        : step <= 32
        ? 1
        : step <= 35
        ? 1 - (step - 32) * 0.2
        : step <= 36.5
        ? 0
        : step > 90
        ? 95 - step
        : step * 1 - 36.5
    }`,
    transform: `rotate(${
      step <= 32
        ? 0
        : step <= 35
        ? (step - 32) * 18
        : step <= 36.5
        ? -20
        : step <= 40
        ? -20 + (step - 36.5) * 5.714
        : step <= 50
        ? 0
        : step <= 60
        ? 0 - (step - 50) * 2.5
        : step <= 65
        ? -25 - (step - 60) * 2
        : step <= 70
        ? -35 + (step - 65) * 3
        : step <= 75
        ? -20 + (step - 70) * 20
        : step <= 77
        ? 80 + (step - 75) * 20
        : step <= 80
        ? 120 + (step - 77) * 38
        : step <= 90
        ? 234 + (step - 80) * 3.6
        : 270
    }deg)`,
    zIndex: "2",
    transition: "0.2",
  };

  const signal1_style = {
    transform: `scale(${-25 + step})`,
    opacity: `${step <= 25 ? 0 : 28 - step}`,
    display: `${step <= 25 || step > 30 ? "none" : "inline"}`,
  };
  const signal2_style = {
    transform: `scale(${-26 + step})`,
    opacity: `${step <= 26 ? 0 : 28.5 - step}`,
    display: `${step <= 25 || step > 30 ? "none" : "inline"}`,
  };
  const signal3_style = {
    transform: `scale(${-26.5 + step})`,
    opacity: `${step <= 26.5 ? 0 : 29.5 - step}`,
    display: `${step <= 25 || step > 30 ? "none" : "inline"}`,
  };

  const signale1_style = {
    transform: `scale(${-25.5 + step})`,
    opacity: `${step <= 25.5 ? 0 : 28 - step}`,
    display: `${step <= 25 || step > 30 ? "none" : "inline"}`,
  };
  const signale2_style = {
    transform: `scale(${-26.5 + step})`,
    opacity: `${step <= 26.5 ? 0 : 28.5 - step}`,
    display: `${step <= 25 || step > 30 ? "none" : "inline"}`,
  };
  const signale3_style = {
    transform: `scale(${-27 + step})`,
    opacity: `${step <= 27 ? 0 : 29.5 - step}`,
    display: `${step <= 25 || step > 30 ? "none" : "inline"}`,
  };

  const signale_static_style = {
    display: `${step >= 29.6 && step < 31 ? "inline" : "none"}`,
  };

  const signal_static_style = {
    display: `${step >= 29.6 && step < 31 ? "inline" : "none"}`,
  };

  const orbit_style = {
    transform: `skew(-40deg, 15deg) rotate(${step * 4 + 40}deg)`,
    opacity: `${
      step <= 36
        ? 0
        : step <= 40
        ? 0 + (step - 36) * 0.2
        : step <= 50
        ? 1 - (step - 40) * 0.7
        : 0
    }`,
    left: `${step <= 40 ? (step - 30) * 3 - 7 : 23}%`,
    bottom: `${step <= 41 ? (step - 30) * 5.2 - 30 : 27.2}%`,
  };

  const orbit_start_style = {
    opacity: `${
      step <= 33
        ? 0
        : step <= 35
        ? 0 + (step - 33) * 0.15
        : step <= 36.5
        ? 0.3 - (step - 35) * 0.15
        : 0
    }`,
  };

  const orbit_finished_style = {
    opacity: `${
      step <= 38
        ? 0
        : step <= 40
        ? 0 + (step - 38) * 0.5
        : step <= 50
        ? 1 - (step - 40) * 0.5
        : 0
    }`,
    transform: ` scale(7, 1) rotate(${
      step <= 36.5 ? -50 : step <= 40 ? -50 + (step - 36.5) * 4.285 : -35
    }deg)`,
    left: `${
      step <= 36.5 ? 30 : step <= 40 ? 30 + (step - 36.5) * 5.714 : 50
    }%`,
    top: `${step <= 36.5 ? 50 : step <= 40 ? 50 - (step - 36.5) * 5 : 30}%`,
  };

  const lunar_orbit_style = {
    opacity: `${step <= 77 ? 0 : step <= 80 ? (step - 77) * 0.34 : 1}`,
  };

  const landing_zone_style = {
    opacity: `${step >= 80.5 ? 1 : step >= 78 ? step - 78 : 0}`,
    display: `${step < 90 && step > 77 ? "inline-block" : "none"}`,
    top: `${step <= 80 ? 22 : step <= 90 ? 22 + (step - 80) * 1.8 : 40}%`,
    left: `${step <= 80 ? 44 : step <= 90 ? 44 - (step - 80) * 0.2 : 42}%`,
  };

  const landing_finished_style = {
    display: `${step >= 90 ? "inline-block" : "none"}`,
    top: `${step <= 90 ? 40 : 40 - (step - 90) * 1.7}%`,
    left: `${step <= 90 ? 42 : 42 - (step - 90) * 0.6}%`,
    width: `${step <= 90 ? 7 : 7 + (step - 90) * 2}vw`,
    height: `${step <= 90 ? 7 : 7 + (step - 90) * 2}vw`,
    borderRadius: `${step <= 90 ? 7 : 7 + (step - 90) * 2}vw`,
    opacity: `${step <= 95 ? 1 : 1 - (step - 95) * 0.1}`,
  };

  const mission_two = {
    display: `${step >= 95 ? "flex" : "none"}`,
    opacity: `${step <= 100 ? step - 99.2 : 1}`,
  };

  return (
    <div className="App">
      {step > 2 ? null : (
        <div className="helper-box">
          <button className="helper" onClick={()=>props.onHelp(!help)}>
            ?
          </button>
          <Link to="/home" className="link-home">H•ME</Link>
          <Link to="/hakuto-r" className="link-module">M•DULE</Link>
        </div>
      )}
      {help === true && step < 1 ? (
        <HelperStory
          onCloseHelper={(e) => props.onHelp(e)}
          onEng={(val) => props.onEng(val)}
          eng={eng}
        />
      ) : null}
      <div className="Main-screen" style={main_screen_style}>
        {step === 20 ||
        step === 40 ||
        step === 50 ||
        step === 60 ||
        step === 70 ||
        step === 80 ? (
          <div className="hint_box">
            {hint ? (
              <div className="hint-explain">
                {eng === true
                  ? "Try to hover over or click on elements on the screen."
                  : "エレメントをマウスホバーやクリックしてみてください。"}
              </div>
            ) : null}
            <div
              className="hint"
              onMouseEnter={() => hintHandler(true)}
              onMouseLeave={() => hintHandler(false)}
            >
              !
            </div>
          </div>
        ) : null}
        {interesting4 === true && step === 20 ? (
          <div className="photo_3_box">
            <div className="photo_3_circle" />
            <div className="photo_3_pointer" />
            <span className="photo_3_caption">
              {eng === true
                ? `Stunning picture of Earth from Canadensys Aerospace AI-enabled
              lunar 360-degree imaging system, which was mounted on Hakuto-r
              module.（©Canadensys Aerospace）`
                : `HAKUTO-Rミッション1ランダーに搭載されているCanadensys Aerospace社の360°カメラで撮影された地球（©Canadensys Aerospace）`}
            </span>{" "}
            <img
              src={canadensys}
              alt="hakuto_first_photo"
              className="photo_3"
            />
          </div>
        ) : null}
        {interesting === true && step === 40 ? (
          <div className="photo_1_box">
            <img src={moonpic} alt="hakuto_first_photo" className="photo_1" />
            <div className="photo_1_pointer" />
            <div className="photo_1_circle" />
            <span className="photo_1_caption">
              {eng === true
                ? `An image of Earth about 19 hours after separation from the launch
              vehicle. (©ispace)`
                : `ロケットから分離されて約19時間後に撮影した地球（©ispace）`}
            </span>
          </div>
        ) : null}
        {interesting === true && step === 50 ? (
          <div className="record_box">
            <span className="record_caption">
              {eng === true
                ? `1.376 million kilometers from Earth on Jan. 20, thus "becoming the
              farthest privately funded, commercially operating spacecraft to
              travel into space"`
                : `2023年1月20日に地球から137.6万キロを離れて、Hakuto-Rは、民間資金で商業運用され宇宙に到達した宇宙船としては最も遠いところとなりました。`}
            </span>
            <div className="record_pointer" />
            <div className="record_circle" />
          </div>
        ) : null}
        {cookie === true && step === 60 ? (
          <div className="cookie_box">
            {" "}
            <div className="cookie_circle" />
            <div className="cookie_pointer" />
            <span className="cookie_caption">
              {eng === true
                ? `Random Moon cookie recipe: Mix 1 cup of landing ground oats, 1 cup
              knowledge flour, a bit of salt of Earth, 3/5 cup of cut date fruit
              planted the day a man did small step and mankind one giant leap
              (MBRSC might have more details). Small spoon of cocoa for proper
              ignition and a cinnamon to propel the taste. Squeeze it up, then
              sweeten more with Canadian maple syrup (Canadensys might have more
              details) and 2 eggs - 1 bigger 1 small and 1/2 cup of melted
              butter because it goes with everything. Mix and squeeze again,
              then shape small moons on baking pan (3cm - 3,474.8 km) and bake
              on 175°C/448.15K/347°F for about 25 minutes. You can add some
              chocolate chips craters.`
                : `月クッキーレシピ: オートミール１杯とパン粉１杯とデーツ3/5杯とココアと少し塩とシナモンをちゃんとう混ぜて,
                メープルシロップ2/5杯、卵2個、1/2杯バターを入れて、手で絞ってください。できたら、ミックスから焼き型で月の形の作ってください。3～5センチは結構です。それから、175°C/448.15K/347°Fでオーブン焼いてください。焼いたクッキーにチョコチップスなど入れても美味しいですよ。`}
            </span>
          </div>
        ) : null}
        {interesting2 === true && step === 80 ? (
          <div className="landing_trivia_box">
            <div className="landing_trivia_pointer" />
            <span className="landing_trivia_caption">
              {eng === true
                ? `Lacus Somniorum the Lake of Dreams, was poetically decided to be
              the final destination of Hakuto-R. Atlas Crater at Mare Frigoris
              (Sea of Cold), Sinus Iridium (Bay of Rainbows) and Oceanus
              Procellarum (Ocean of Storms) were also considered.`
                : `最終的に、Hakuto-Rの到着地は詩的に夢の湖(Lacus Somniorum)になりました。寒さの海(Mare Frigoris)にあるアトラスクレーター
              , 虹の入江(Sinus Iridium)、嵐の大洋(Oceanus Procellarum)も検討されました。`}
            </span>
          </div>
        ) : null}
        {interesting3 === true && step === 70 ? (
          <div className="photo_2_box">
            <span className="photo_2_caption">
              {eng === true
                ? `Hakuto-R Spacecraft Version of famous ‘Earthrise’ (Apollo 8)（©ispace）`
                : `Hakuto-Rは、有名な地球の出写真（アポロ8）の自分のバージョンを撮影できました。（©ispace）`}
            </span>
            <img
              src={mooneclipse}
              alt="hakuto_second_photo"
              className="photo_2"
            />

            <div className="photo_2_circle" />
          </div>
        ) : null}
        {modal === true ? (
          <InfoScreen
            onCloseInfoScreen={(e) => modalHadler(e)}
            success={success}
            eng={eng}
          />
        ) : null}
        <div className="ispace_nav" style={ispace_nav_style}>
          <a href="https://ispace-inc.com/" target="_blank" rel="noreferrer">
            <img src={ispace_w} alt="ispace_logo" className="ispace_logo" />
          </a>
          <div
            onMouseEnter={() => interesting3Handler(true)}
            onMouseLeave={() => interesting3Handler(false)}
          >
            <img src={hakuto} alt="hakuto_logo" className="hakuto_logo" />
            <span>HAKUTO-R Mission 1</span>
          </div>
        </div>
        <div className="ispace_init">
          <div style={hakuto_logo_style}>
            <img
              src={hakuto}
              alt="hakuto_logo"
              style={{ width: "3vw", marginRight: "1vw" }}
            />
            <span style={hakuto_title}> The story of HAKUTO-R Mission 1</span>
          </div>
          
          <img src={ispace} style={ispace_logo_style} alt="ispace_logo" />
  
        </div>

        <div className="rocket_light" style={rocket_light_style} />
        <img
          className="hakuto_booster"
          src={booster}
          alt="falcon_booster"
          style={booster_style}
        />
        <img
          style={earth_style}
          className="earth-static"
          src={earth}
          alt="Planet earth"
          onMouseEnter={() => interesting4Handler(true)}
          onMouseLeave={() => interesting4Handler(false)}
        ></img>
        <img
          style={moon_style}
          className="moon-static"
          src={moon}
          alt="moon"
          onMouseEnter={() => cookieHandler(true)}
          onMouseLeave={() => cookieHandler(false)}
        ></img>
        <div className="lunar-orbit-static" style={lunar_orbit_style}>
          <div className="lunar-orbit" />
        </div>
        <div
          className="landing-zone"
          style={landing_zone_style}
          onMouseEnter={() => interesting2Handler(true)}
          onMouseLeave={() => interesting2Handler(false)}
        />
        <div className="landing-zone-finished" style={landing_finished_style} />
        <div className="signale" style={signale1_style} />
        <div className="signale" style={signale2_style} />
        <div className="signale" style={signale3_style} />
        <div className="signale signale-static" style={signale_static_style} />
        <div className="orbit_start" style={orbit_start_style}></div>
        <div className="orbit_static">
          <div className="orbit" style={orbit_style}></div>
        </div>
        <div className="orbit_static">
          <div className="orbit_finished" style={orbit_finished_style}></div>
        </div>
        <div className="hakuto_module_rotation" style={hakuto1_box_style}>
          <div className="hakuto_module_box">
            <img
              className="hakuto_module"
              src={hakuro1}
              alt="ispace_mission1"
              style={hakuto1_style}
              onMouseEnter={() => interestingHandler(true)}
              onMouseLeave={() => interestingHandler(false)}
            />
            <img
              className="hakuto_thrust"
              src={thrust}
              alt="ispace_mission1"
              style={hakuto1_thrust_style}
            />
            <div className="signal" style={signal1_style} />
            <div className="signal" style={signal2_style} />
            <div className="signal" style={signal3_style} />
            <div className="signal signal-static" style={signal_static_style} />
          </div>
        </div>
        {step > 5 && step < 15 ? (
          <div className="success_box" style={success1_style}>
            <div className="success_box_top">
              <h2>SUCCESS 1 ✔ </h2>
              <button
                onClick={() => {
                  modalHadler();
                  successHandler(1);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_right">
              <h3>
                {eng === true
                  ? "COMPLETION OF LAUNCH PREPARATIONS"
                  : "打ち上げ準備の完了"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Complete all development processes of the Series 1 lunar lander before flight operations."
                    : "シリーズ 1 ランダーの全ての開発工程を完了。"}
                </li>
                <li>
                  {eng === true
                    ? "Contract and prepare launch vehicle, and complete integration of lunar lander into the launch vehicle."
                    : "打ち上げロケットへの搭載が完了。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 15 && step < 25 ? (
          <div className="success_box" style={success2_style}>
            <div className="success_box_top">
              <h2>SUCCESS 2 ✔</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(2);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_left">
              <h3>
                {eng === true
                  ? "COMPLETION OF LAUNCH AND DEPLOYMENT"
                  : "打ち上げ及び分離の完了"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Complete succesful separation of the lunar lander from the launch vehicle."
                    : "ロケットからランダーの分離が完了"}
                </li>
                <li>
                  {eng === true
                    ? "Prove that the lander's structure is capable of withstanding the harsh conditions during launch, validating the design and gathering information towards future developments and missions."
                    : "ランダーの構造が打ち上げ時の過酷な条件に耐えられることを証明し、設計の妥当性を確認するとともに、将来の開発・ミッションに向けてデータを収集。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 25 && step < 35 ? (
          <div className="success_box" style={success3_style}>
            <div className="success_box_top">
              <h2>SUCCESS 3 ✔</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(3);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_right">
              <h3>
                {eng === true
                  ? "ESTABLISHMENT OF A STEADY OPERATION STATE"
                  : "安定した航行状態の確立"}
              </h3>
              <span>
                {" "}
                {eng === true
                  ? " (*Initial Critical Opetation Status)"
                  : "（※初期クリティカル運用状態）"}
              </span>
              <ul>
                <li>
                  {eng === true
                    ? " Establish communication link between the lander and Mission Control Center, confirm a stable attitude, as well as start stable generation of electrical power in orbit. The completion of this step verifies the integrity of lander core systems and customer payloads"
                    : "ランダーと管制室との通信を確立し、姿勢の安定を確認するとともに、軌道上で安定した電源供給を確立。ランダーの基幹システムおよび顧客 ペイロードに不備がないことを確認。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 35 && step < 45 ? (
          <div className="success_box" style={success4_style}>
            <div className="success_box_top">
              <h2>SUCCESS 4 ✔</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(4);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_left">
              <h3>
                {eng === true
                  ? "COMPLETION OF FIRST ORBITAL CONTROL MANEUVER"
                  : "初回軌道制御マヌーバの完了"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Complete the first orbital control maneuver, setting the lander on a cource towards the Moon and verifying operation of the main propulsion system, as well as related guidance, control, and navigation system."
                    : "初回の軌道制御マヌーバを実施し、ランダーを予定軌道へ投入するとともに、主推進系、誘導制御系の動作を確認。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 45 && step < 55 ? (
          <div className="success_box" style={success5_style}>
            <div className="success_box_top">
              <h2>SUCCESS 5 ✔</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(5);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_right">
              <h3>
                {eng === true
                  ? "COMPLETION OF STABLE DEEP-SPACE FLIGHT OPERATIONS FOR ONE MONTH"
                  : "深宇宙航行の安定運用を 1 ヶ月間完了"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Prove that the lander is capable of steady deep-space flight by completing a nominal cruise and orbital control maneuvers over a 1 month period."
                    : "1 ヶ月間にわたるノミナルクルーズと軌道制御マヌーバを行い、ランダーが安定して深宇宙航行が可能であることを実証。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 55 && step < 65 ? (
          <div className="success_box" style={success6_style}>
            <div className="success_box_top">
              <h2>SUCCESS 6 ✔</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(6);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_left">
              <h3>
                {eng === true
                  ? "COMPLETION OF ALL DEEP SPACE ORBITAL CONTROL MANEUVERS BEFORE LOI"
                  : "LOI 前の全ての深宇宙軌道制御マヌーバの完了"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Complete all planned deep space orbiral control maneuvers by utilizing gravity assist effects and successfully target the first lunar orbit insertion maneuver. This stage proves the ability of the lander's deep-space survivability, as well as the viability of ispace's orbital planning."
                    : "太陽の重力を利用した全ての深宇宙軌道制御マヌーバを完了し、月周回軌道投入マヌーバの準備を完了。 ispace の深宇宙におけるランダー運用能力と、航行軌道計画を実証。"}
                  <br />
                  {eng === true
                    ? "(LOI = Lunar Orbit Insertion)"
                    : "※LOI = 月周回軌道投入（Lunar Orbit Insertion）"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 65 && step < 75 ? (
          <div className="success_box" style={success7_style}>
            <div className="success_box_top">
              <h2>SUCCESS 7 ✔</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(7);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_right">
              <h3>
                {eng === true
                  ? "REACHING THE LUNAR GRAVITATIONAL FIELD/LUNAR ORBIT"
                  : "月重力圏への到達／月周回軌道への到達"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Complete the first lunar orbit insertion maneuver and confirm　the lander is in a lunar orbit, verifying the ability of　ispace to deliver spacecraft and payloads into stable lunar　orbits."
                    : "最初の月周回軌道投入マヌーバによるランダーの月周回軌道投入の完了。ランダーとペイロードを月周回軌道に投入する能力を実証。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 75 && step < 85 ? (
          <div className="success_box" style={success8_style}>
            <div className="success_box_top">
              <h2>SUCCESS 8 ✔</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(8);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_left">
              <h3>
                {eng === true
                  ? "COMPLETION OF ALL ORBIT CONTROL MANEUVERS IN LUNAR ORBIT"
                  : "月周回軌道上での全ての軌道制御マヌーバの完了"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Complete all planned lunar orbital control maneuvers before landing sequence."
                    : "着陸シーケンスの前に計画されている全ての月軌道制御マヌーバを完了。"}
                </li>
                <li>
                  {eng === true
                    ? "Confirm the lander is ready to start the landing sequence."
                    : "ランダーが着陸シーケンスを開始する準備ができていることを実証。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 85 && step < 95 ? (
          <div className="success_box" style={success9_style}>
            <div className="success_box_top">
              <h2>SUCCESS 9</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(9);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_right">
              <h3>
                {eng === true
                  ? "COMPLETION OF LUNAR LANDING"
                  : "月面着陸の完了"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Complete the landing sequence, verifying key landing abilities for future missions."
                    : "月着陸を完了させ、今後のミッションに向けた着陸能力を実証。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {step > 95 ? (
          <div className="success_box" style={success10_style}>
            <div className="success_box_top">
              <h2>Success 10</h2>
              <button
                style={{ zIndex: "300" }}
                onClick={() => {
                  modalHadler();
                  successHandler(10);
                }}
              >
                i
              </button>
            </div>
            <div className="success_text success_text_left">
              <h3>
                {eng === true
                  ? "ESTABLISHMENT OF STEADY SYSTEM STATE AFTER LUNAR LANDING"
                  : "月面着陸後の安定状態の確立"}
              </h3>
              <ul>
                <li>
                  {eng === true
                    ? "Establish a steady telecommunication and power supply on the lunar surface after landing to support customer payloads' surface operations."
                    : "着陸後の月面での安定した通信と電力供給を確立し、顧客のペイロード運用能力を実証。"}
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <div className="mission_two" style={mission_two}>
          <h4>2024</h4>
          <h3>
            <a
              href={
                eng === true
                  ? "https://ispace-inc.com/missions"
                  : "https://ispace-inc.com/jpn/missions"
              }
              target="_blank"
              rel="noreferrer"
            >
              MISSION 2
            </a>
          </h3>
          {eng === true ? (
            <span>Why do we fall? So we can learn to pick ourselves up.</span>
          ) : (
            <span>「人はどうして落ちるのか？はい上がることを学ぶためさ」</span>
          )}
        </div>

        <div className="player_box">
          <button onClick={startStep} style={{ fontSize: "2.5vw" }}>
            ▸
          </button>

          <button onClick={stopStep}>II</button>

          <button onClick={resetStep} style={{ fontSize: "3vw" }}>
            ▪
          </button>
        </div>
      </div>
      <div className="Slider-screen">
        <div className="Slider-box">
          <input
            type="range"
            min={0}
            max={100.1}
            step={0.1}
            value={step}
            onChange={handleChange}
            className="slider"
          />
          <div className="slider_range">
            <div className="slider_lower" style={{ width: `${step}%` }} />
          </div>
          <div className="slider_buttons">
            <button
              style={{ color: `${step >= 0 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(0);
              }}
            >
              Start
            </button>
            <button
              style={{ color: `${step >= 10 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(10);
              }}
            >
              Success 1
            </button>
            <button
              style={{ color: `${step >= 20 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(20);
              }}
            >
              Success 2
            </button>
            <button
              style={{ color: `${step >= 30 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(30);
              }}
            >
              Success3
            </button>
            <button
              style={{ color: `${step >= 40 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(40);
              }}
            >
              Success 4
            </button>
            <button
              style={{ color: `${step >= 50 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(50);
              }}
            >
              Success 5
            </button>
            <button
              style={{ color: `${step >= 60 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(60);
              }}
            >
              Success 6
            </button>
            <button
              style={{ color: `${step >= 70 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(70);
              }}
            >
              Success 7
            </button>
            <button
              style={{ color: `${step >= 80 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(80);
              }}
            >
              Success 8
            </button>
            <button
              style={{ color: `${step >= 90 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(90);
              }}
            >
              Success 9
            </button>
            <button
              style={{ color: `${step >= 100 ? "rgb(255, 60, 0)" : "black"}` }}
              onClick={() => {
                successStepHandler(100);
              }}
            >
              Success 10
            </button>
          </div>
        </div>
      </div>
      <div className="Bottom-hider" />
    </div>
  );
}

export default Story;
