import "./App.css";
import "./Range.css";
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import { useState } from "react";

import Login from "./Components/Login";
import Home from "./Components/Home";
import Story from "./Components/Story";
import ModulScene from "./Components/ModulScene"

function App() {

  const [eng, setEng] = useState(true);
  const engHandler = (value) => {
    setEng(value);
  };

  const [help, setHelp] = useState(false);
  const helpHandler = (value) => {
    setHelp(value);
  };

  
  const router = createBrowserRouter([
  {path:'/', element:
    <Login onEng={(val) => engHandler(val)} eng={eng}/>},
  {path:'/home', element:
    <Home onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>},
  {path:'/story', element:
    <Story onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>},
  {path:'/hakuto-r', element: 
    <ModulScene onEng={(val) => engHandler(val)} eng={eng} onHelp={(val)=>helpHandler(val)} help={help}/>}
  ])

  return (
    <>
      <RouterProvider router={router}/>
    </>
  )

}

export default App;
