import "../App.css";
import "../Range.css";
import "./Home.css";
import { useRef, useEffect, useState } from "react";

import {Link} from 'react-router-dom'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../FirebaseConfig';
import { NavLink, useNavigate } from 'react-router-dom'

//img
import ispace_w from "../Images/logo_ispace_white.svg";
import anim_gr from "../Videos/ispace-logo-animation-concept-graphic.mp4"
import anim_bl from "../Videos/ispace-logo-animation-concept-min-black.mp4"
import anim_wt from "../Videos/ispace-logo-animation-concept-min-white.mp4"

function Home(props) {
  const eng = props.eng
  
  const top = useRef();
  const about = useRef();
  const video = useRef()
  const story = useRef()
  const module = useRef()

  const navigate = useNavigate();

  const [videoSelected,setVideoSelected] = useState(anim_gr)
  const videoSelectedHandler = (vid) => {
     setVideoSelected(vid)
  }

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          //console.log("uid", uid)
        } else {
          // User is signed out
          navigate("/");
          console.log("user is logged out")
        }
      });
     
}, [])


  const scrollTo = (to) => {
    to.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
      const home = document.getElementById("home");
      const hook = document.getElementById("hook");
      const handleScroll = () => {
        if (home.scrollTop > hook.getBoundingClientRect().top + 200 ) {
          setShowMenu(true);
        } else {
          setShowMenu(false);
        }

      };
        
      home.addEventListener('scroll', handleScroll);
      return () => {
      home.removeEventListener('scroll', handleScroll);
      };
    },[]);
  

  return (
    <main className="home" id="home">
      <div
       className="background-fixed"      
       />
      <div className="section_nav">
        <div className="nav-box" style={showMenu? {transition:'1s', opacity: "1"}:{transition:'0.3s', opacity: "0"} } >
          <button onClick={()=> scrollTo(top)}>T•P</button>
          <button onClick={()=> scrollTo(about)}>AB•UT</button>
          <button onClick={()=> scrollTo(video)}>L•G•</button>
          <button onClick={()=> scrollTo(story)}>ST•RY</button>
          <button onClick={()=> scrollTo(module)}>M•DULE</button>

        </div>
        <div className="lang-box">
          <button
            onClick={() => props.onEng(true)}
            className={eng === true ? "selected" : ""}
          >
            English
          </button>
          <button
            onClick={() => props.onEng(false)}
            className={eng !== true ? "selected" : ""}
          >
            日本語
          </button>
        </div>
      </div>
      <div ref={top} className="section_top">
          <button className="planet planet__a" onClick={()=> scrollTo(about)}>
            <div className="planet__inner">AB•UT</div>
          </button>
          <button className="planet planet__b" onClick={()=> scrollTo(video)}>
            <div className="planet__inner">L•G•</div>
          </button>
          <button className="planet planet__c" onClick={()=> scrollTo(story)}>
            <div className="planet__inner">ST•RY</div>
          </button>
          <button className="planet planet__d" onClick={()=> scrollTo(module)}>
            <div className="planet__inner">M•DULE</div>
          </button>
          <div className="planet__e">
            <div className="welcome-box">
              <h3>
                {eng === true
                  ? "WELCOME TO SPACEFICATION"
                  : "SPACEFICATIONへようこそ。"}
              </h3>
            </div>
            <div className="welcome-bottom">
              <h4>
              {eng === true
                    ? "AN INTERACTIVE SITE CREATED FOR "
                    : ""}
              </h4>
              <a className="a_link" href="https://ispace-inc.com/" target="_blank" rel="noreferrer"><img alt="ispace logo" src={ispace_w}/></a>
              <h4>
              {eng === true
                    ? ""
                    : "のファンに制作されたインタラクティブのサイト。"}
              </h4>
            </div>
            <button className="scrolldown-btn" onClick={()=> scrollTo(about)}/>
          </div>
      </div>
      <div id="hook" ref={about} className="section_wrapper section_wrapper-top">
          <span className="heading_wrapper-l"><h4 className="heading heading-about">AB•UT</h4></span>
          <div className="block_wrapper">
            <div className="section_block section_block-about">
            {eng === true ? (  
              <p>
                Spacefication is a small project created as part of my application for the web designer position at the awesome space startup - ispace. Through this project, I aim to showcase my web design skills, creativity, and enthusiasm.
                I've come up with three small ideas -  <strong>Logo</strong>, <strong>Story</strong> and <strong>Module</strong>, to pay tribute to the company and its first mission to the moon.
              </p>
            ) : (
              <p>
                Spaceficationは、ウェブデザイナー職の応募の一環として作成した小さなプロジェクトです。このプロジェクトを通じて、私のウェブデザインスキル、創造性、熱意をアピールしたいと考えています。私は会社とその最初の月への使命に敬意を表すために、ロゴ、ストーリー、モジュールの3つのアイデアを紹介させていただきます。
              </p>
            )}
            </div> 
            <span className="heading_wrapper-r"><h4 className="heading heading-logo">L•G•</h4></span>  
          </div>
          <div ref={video} className="section_wrapper section_wrapper-video"> 
            <div className="section_block section_block-video">         
                  {eng === true ? (
                    <p>
                      Animation is my hobby from high school. I don't aim to create movies or complex stories, but in my professional life I always like to spice up my project with a little bit of motion to help catch interest of the target group.<br/>
                      This is a concept of a simple ispace logo animation, which could be use for example on social media, presentations or digital banners. There are 3 versions - one with texture in space (graphic), one with black background and one with white background. Made with Illustrator and After Effects.
                    </p>
                  ) : (
                    <p>
                      アニメーションは私の高校時代からの趣味です。映画や複雑なストーリーを作成することを目指していませんが、ターゲット層の興味を引くように、よくアニメーションを加えながら、魅力を加えます。
                      <br/>
                      ここはシンプルなispaceロゴアニメーションのコンセプトです。ソーシャルメディア、プレゼンテーション、またはデジタルバナーなどで使用できます。テクスチャのある宇宙のバックグラウンド(GRAPHIC)、黒いバックグラウンド(BLACK)、白いバックグラウンド(WHITE)の3つのバージョンがあります。IllustratorとAfter Effectsで制作しました。
                    </p>
                  )}
            </div>
            <div className="video__nav">
                <button className={videoSelected === anim_gr ? 'video__selected' : ''} onClick={()=>{videoSelectedHandler(anim_gr)}}>GRAPHIC</button>
                <button className={videoSelected === anim_bl ? 'video__selected' : ''} onClick={()=>{videoSelectedHandler(anim_bl)}}>BLACK</button>
                <button className={videoSelected === anim_wt ? 'video__selected' : ''} onClick={()=>{videoSelectedHandler(anim_wt)}}>WHITE</button>
            </div>
            <div className="video__wrapper">
              <video src={`${videoSelected}#t=0.5`} type="video/mp4" width="100%" height="auto" controls controlsList="nodownload"/>
            </div>    
            <div className="block-video-background"/>  
          </div>
      </div>   
      <div  ref={story} className="section_wrapper-story">
        <div className="section_block section_block-story">    
          <div className="link_box link_box-story">
                <Link to="/story" className="link_home-story link-big">ST•RY</Link>
          </div>  
          <div className="section_block-text">
                    {eng === true ? (
                      <p>
                        Part of a web designer job is analyzing the project target group. I believe that space exploration attracts people who are curious and eager to discover answers.<br/>
                        The story app is a concept of a web about HAKUTO-R Mission 1, which combines providing information by engaging curiosity in visual and interactive environemnt.<br/>
                        <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                          Open by clicking the story logo. Explore and be curious. If you are visiting the page on a smartphone, rotate your screen to landscape mode for better experience.
                          After visiting the page, you can click <span
                          style={{
                            color: "rgba(255, 68, 0, 1)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >?</span> button to get more instructions. </p>
                      </p>
                    ) : (
                      <p>
                      ウェブデザイナーの仕事の一部はプロジェクトのターゲット層を分析することです。宇宙探査は好奇心を持ている人々と興味津々で答えを見つけることに熱心な人々を引き寄せると信じています。
                      このストーリーアプリは、HAKUTO-Rミッション1に関するウェブのコンセプトで、視覚的でインタラクティブな環境で好奇心を掻き立てる情報提供を組み合わせています。<br/>   
                      <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                      ストーリーロゴをクリックして開いてください。探索し、好奇心を持ってください。スマートフォンでページを訪れている場合は、より良い体験のために画面を横向きに回転させてください。ページを訪れた後、<span
                          style={{
                            color: "rgba(255, 68, 0, 1)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >?
                        </span>ボタンをクリックして指示を取得できます。
                        </p>
                      </p>
                    )}
          </div>
        </div>
      </div>
      <div ref={module} className="section_wrapper-module">
          <div className="section_block section_block-module">  
            <div className="link_box link_box-module">
              <Link to="/hakuto-r" className="link_home-module link-big">M•DULE</Link>
            </div>
            <div className="section_block-text section_block-text-module">
            {eng === true ? (
              <p>
                As a professional I have to keep up with technologies and always learn new things. And the best way how to learn is by practice.<br/> 
                Few months ago I created my first simple 3D cube in Three.js, framework for 3D graphics in web. 
                Now, I present you fully 3D Hakuto-r Mission 1 module with interactive features.<br/>And why not to present the new modules in a similar way?  
                <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                Open by clicking the module logo. Explore and be curious. After visiting the page, you can click <span
                        style={{
                          color: "rgba(255, 68, 0, 1)",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >?</span> button to get more instructions. </p>
              </p>
            ):(
              <p>
                プロフェッショナルとして、私は技術の最新情報を追いかけ、常に新しいことを学ぶ必要があります。そして、一番いい学ぶ方法は実践だと思います。
                数ヶ月数か月前、私はWeb上の3DのフレームワークであるThree.jsを使用して、最初の3Dキューブを作成しました。<br/>
                今、完全な3DのHakuto-r Mission 1モジュールをインタラクティブにご紹介します。
                新しいモジュールを同様の方法で紹介するのはどうでしょうか。
                <p style={{fontSize:'0.8rem', lineHeight:'0.8rem'}}>
                      ストーリーロゴをクリックして開いてください。探索し、好奇心を持ってください。ページを訪れた後、<span
                          style={{
                            color: "rgba(255, 68, 0, 1)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >?
                        </span>ボタンをクリックして指示を取得できます。
                        </p>
              </p>
            )}

            </div>
          </div>
      </div>  
      <div className="section_author">
            <div className="section_block section_block-author">    
            <h4>AUTH•R</h4>    
              {eng === true ? (
                <p>
                  My name is Aleš and I am a web developer, creative and ispace fan and. :) This project was created with frontend technologies like
                  React.js framework, Three.js (React Three Fiber), HTML5, CSS3 etc. and with graphic software like Illustrator, Photoshop or Blender.
                  You can contact me on this email address{" "}<a className="a_link" href="mailto:ales.bachtik7@gmail.com">ales.bachtik7@gmail.com</a> or you can visit <a className="a_link" href="https://www.ab-miniportfolio.com/">my personal website</a>. 
                </p>
              ) : (
                <p>
                  私はアレシュと申します。創造的にデベロッパーをして、ispaceのファンです。:) ここのプロジェクトはReact.jsやThree.js(React Three Fiber)やHTML5やCSS3などで作りました。グラフィックのためにIllustratorとPhotoshopとBlenderを利用しました。ご興味がある方は、<a className="a_link" href="mailto:ales.bachtik7@gmail.com">ales.bachtik7@gmail.com</a>のメールにご連絡ください。私の<a className="a_link" href="https://www.ab-miniportfolio.com/">パーソナルページ</a>を訪問してくさだら、幸いです。
                </p>
              )}</div>

      </div>  
      <span className="photo-by">Background photo by <a className="a_link" href="https://unsplash.com/@danesduet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Daniel Olah</a> on <a className="a_link" href="https://unsplash.com/photos/HNkgPFBShSw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </span>
    </main>
  );
}

export default Home;
