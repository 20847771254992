import React from "react";
import "./Helper.css";

function Helper(props) {
  return (
    <div className="Helper-box">
      <div className="Helper-nav">
        <div className="lang-box">
          <button
            onClick={() => props.onEng(true)}
            className={props.eng === true ? "selected" : ""}
          >
            English
          </button>
          <button
            onClick={() => props.onEng(false)}
            className={props.eng !== true ? "selected" : ""}
          >
            日本語
          </button>
        </div>
        <button className="Helper-close" onClick={()=>props.onCloseHelper(false)}></button>
      </div>
      <div className="Helper-content">
        {props.eng === true ? (
          <p>
            <strong>Explore！</strong><br/>
            <ul>
              <li> You can either play with the play/pause/stop buttons
              or you can move slowly slider by yourself and have full control of
              the flow.</li>
              <li>
              here are 10 stops based on ispace mission success points.
              On every stop you can open a window with interesting news articles
              about that specific event{" "}
                <span
                  style={{
                    color: "rgba(255, 68, 0, 1)",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  i
                </span>.
              </li>
              <li>
                  If you see{" "}
                <span
                  style={{
                    color: "rgba(255, 68, 0, 1)",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  !
                </span>{" "}
                on one of the success stops, it means there is a hidden element
                somewhere on the screen. Try to find it with your mouse!
              </li>
              <li>
              On phones, please rotate the screen to landscape mode.
              </li>
            </ul>
          </p>
        ) : (

          <p>
          <strong>探検してみて！</strong>
          <ul>
            <li>play/pause/stopのボタンを使用してもいいし、スライダーのボタンを押しながら、自分の流れでも楽しめます。</li>
            <li>
            ミッション1のマイルストーン/サクセスクを元にして、ストップは10があります。それぞれのストップで新しいウィンドウを開けます。このウィンドウに、このストップに関するニュースの記事のリストがあります。{" "}
            <span
              style={{
                color: "rgba(255, 68, 0, 1)",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              i
            </span>{" "}
            を探してみてください。
            </li>
            <li>
            <span
              style={{
                color: "rgba(255, 68, 0, 1)",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              !
            </span>{" "}
            を見つけたら、宇宙の中で隠れたエレメントも入っています。マウスを動かしたら探してみてください。
            </li>
            <li>
            携帯電話で見る場合、ランドスケープモードで楽しんでください。
            </li>
          </ul>
        </p>
        )}
      </div>
    </div>
  );
}

export default Helper;
